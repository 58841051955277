import APIService from '@/services/api-service'
const APP_VERSION = 'app_version'
const resource = '/app'

class SettingService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getLocalAppVersion () {
    return window.localStorage.getItem(APP_VERSION)
  }

  public setLocalAppVersion (value) {
    return window.localStorage.setItem(APP_VERSION, value)
  }

  public destroyAppVersion () {
    return window.localStorage.removeItem(APP_VERSION)
  }

  public getServerAppVersion () {
    return this.connector.get(`${resource}/version`)
  }
}

export default new SettingService()
