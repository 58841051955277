/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/company',
    redirect: '/company/details',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi-building',
      title: 'Companies'
    },
    children: [
      {
        path: '/company/details',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '../Index.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi-building',
          title: 'Companies'
        }
      }
    ]
  }
]
