import Layout from '@/layout/index.vue'

export default [
  {
    path: '/summary',
    redirect: 'summary/search',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-bar-chart',
      title: 'Summary'
    },
    children: [
      {
        path: '/summary/search',
        name: 'SearchSummary',
        component: () => import(/* webpackChunkName: "summary" */ '../Summary.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-bar-chart',
          title: 'Search Summary'
        }
      },
      {
        path: '/summary/jobs',
        name: 'JobsSummary',
        component: () => import(/* webpackChunkName: "summary" */ '../components/Job.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-star',
          title: 'Quick Access'
        }
      }
    ]
  }
]
