
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/AppModule'
import Topbar from './components/Topbar/index.vue'
import Sidebar from './components/Sidebar/index.vue'
import AppMain from './components/AppMain.vue'
import Toast from '@/components/Toast/index.vue'
// import Error from '@/components/ErrorModal/index.vue'
import NotifyModal from '@/components/NotifyModal/index.vue'

@Component({
  name: 'Layout',
  components: {
    Topbar,
    Sidebar,
    AppMain,
    Toast,
    // Error,
    NotifyModal
  }
})

export default class Layout extends Vue {
  get isCollapsed () {
    return !AppModule.sidebar.collapsed
  }

  get isToggled () {
    return !AppModule.sidebar.opened
  }
}
