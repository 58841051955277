
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import '@/styles/components/_toast.scss'

  @Component({
    name: 'ToastModal'
  })
export default class ToastModal extends Vue {
    public type: any = null
    public message: any = null
    public isActive = false

    get isMessage () {
      return ToastModule.toast.isSet
    }

    @Watch('isMessage', {
      deep: true
    })
    notifyMessage () {
      if (this.isMessage) {
        this.type = ToastModule.toast.type
        this.message = ToastModule.toast.message
        this.isActive = true
        setTimeout(() => {
          this.close()
        }, 3000)
      }
    }

    // protected setToast () {
    //   const x: any = document.getElementById('simpleToast')
    //   x.className = 'show'
    //   setTimeout(() => {
    //     x.className = x.className.replace('show', '')
    //     this.close()
    //   }, 3000)
    // }

    protected close () {
      this.isActive = !this.isActive
      this.reset()
      ToastModule.removeToast()
    }

    protected reset () {
      this.type = null
      this.message = null
    }
}

