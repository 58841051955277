/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/cost',
    redirect: '/costing',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-journals',
      title: 'Costing'
    },
    children: [
      {
        path: '/costing',
        name: 'Costing',
        component: () => import(/* webpackChunkName: "costing" */ '../Cost.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-journals',
          title: 'Costing'
        }
      }
    ]
  }
]
