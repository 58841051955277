/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/expense',
    redirect: '/expense/categories',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'client-all',
      icon: 'bi bi-journal-plus',
      title: 'Expense Categories'
    },
    children: [
      {
        path: '/expense/categories',
        name: 'ExpenseCategory',
        component: () => import(/* webpackChunkName: "accounts" */ '../Category.vue'),
        meta: {
          authRequired: true,
          permission: 'client-all',
          icon: 'bi bi-people',
          title: 'Expense Categories'
        }
      }
    ]
  }
]
