/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/supplier',
    redirect: '/suppliers',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-journal-plus',
      title: 'Suppliers'
    },
    children: [
      {
        path: '/supplier',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "Supplier" */ '../Supplier.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-people',
          title: 'Suppliers'
        }
      }
    ]
  }
]
