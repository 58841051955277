class AuthenticationError extends Error {
  constructor (message) {
    super(message)
    this.name = this.constructor.name
    this.stack = (new Error()).stack
    this.message = message
    // this.code = errorCode
  }
}

export default AuthenticationError
