/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/vehicles',
    redirect: '/vehicle/table',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-truck-front',
      title: 'Vehicles'
    },
    children: [
      {
        path: '/vehicle/table',
        name: 'Vehicle',
        component: () => import(/* webpackChunkName: "vehicle" */ '../Vehicle.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-truck-front',
          title: 'Vehicles'
        }
      },
      {
        path: '/vehicle/drivers',
        name: 'Driver',
        component: () => import(/* webpackChunkName: "vehicle" */ '../Driver.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-truck-front',
          title: 'Drivers'
        }
      }
    ]
  }
]
