import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface AppState {
  sidebar: {
    opened: boolean
    collapsed: boolean
  }

}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements AppState {
  public sidebar = {
    opened: true,
    collapsed: true
  }

  @Mutation
  private setToggleSidebar () {
    this.sidebar.opened = !this.sidebar.opened
  }

  @Mutation
  private setCollapsedSidebar () {
    this.sidebar.collapsed = !this.sidebar.collapsed
  }

  @Action
  public toggleSideBar () {
    this.setToggleSidebar()
  }

  @Action
  public collapsedSidebar () {
    this.setCollapsedSidebar()
  }
}

export const AppModule = getModule(App)
