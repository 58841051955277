import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { RouteConfig } from 'vue-router'
import { asyncRoutes } from '@/router'
import store from '@/store'

const hasPermission = (permissions: string[], route: RouteConfig) => {
  if (route.meta && route.meta.permission) {
    return permissions.includes(route.meta.permission)
  }
}

export const filterAsyncRoutes = (routes: RouteConfig[], permissions: string[]) => {
  const res: RouteConfig[] = []
  routes.forEach(route => {
    const routeObject = { ...route }
    if (hasPermission(permissions, routeObject)) {
      if (routeObject.children) {
        routeObject.children = filterAsyncRoutes(routeObject.children, permissions)
      }
      res.push(routeObject)
    }
  })
  return res
}

export interface PermissionState {
  // routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements PermissionState {
  // public routes: RouteConfig[] = []
  public dynamicRoutes: RouteConfig[] = []

  @Mutation
  private setRoutes (routes: RouteConfig[]) {
    // this.routes = constantRoutes.concat(routes)
    this.dynamicRoutes = routes
  }

  @Action
  public generateRoutes (permissions: string[]) {
    const accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions)
    this.setRoutes(accessedRoutes)
  }
}

export const PermissionModule = getModule(Permission)
