/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/operation',
    redirect: '/operation/port',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-journal-plus',
      title: 'Ports'
    },
    children: [
      {
        path: '/operation/port',
        name: 'Port',
        component: () => import(/* webpackChunkName: "operation" */ '../Port.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-journals',
          title: 'Ports'
        }
      },
      {
        path: '/operation/terminal',
        name: 'Terminal',
        component: () => import(/* webpackChunkName: "operation" */ '../Terminal.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-journals',
          title: 'Terminals'
        }
      },
      {
        path: '/operation/service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "operation" */ '../Service.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-journals',
          title: 'Services'
        }
      }
    ]
  }
]
