import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import StateService from '../services/state-service'

export interface JobState {
  job: any
  route: any
}

@Module({ dynamic: true, store, name: 'job' })
class Job extends VuexModule implements JobState {
  public job: any = {}
  public route: any = null

  @Mutation
  private setJobData (job: any = {}) {
    this.job = job
  }

  @Mutation
  private setRoute (route: any) {
    this.route = route
  }

  @Mutation
  private remove () {
    this.job = {}
    this.route = null
  }

  @Action
  public setJob (job: any) {
    this.setJobData(job)
  }

  @Action
  public destroy () {
    this.remove()
  }

  @Action
  public setOperationsRoute (route: any) {
    this.setRoute(route)
  }

  @Action
  public async setJobDetails (id: number) {
    try {
      const response = await StateService.getJobDetails(id)
      this.setJobData(response.data)
    } catch (error: any) {
      console.log(error)
    }
  }
}

export const JobModule = getModule(Job)
