import Layout from '@/layout/index.vue'

export default [
  {
    path: '/barging',
    redirect: '/barge',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'ac@job',
      icon: 'bi bi-basket3',
      title: 'Barging'
    },
    children: [
      {
        path: '/barge',
        name: 'Barge',
        component: () => import(/* webpackChunkName: "barge" */ '../Barge.vue'),
        meta: {
          authRequired: true,
          permission: 'ac@job',
          icon: 'bi bi-basket3',
          title: 'Barging'
        }
      }
    ]
  }
]
