import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface NotifyState {
  notify: {
    opened: boolean,
    items: any,
    message: any
  }

}

@Module({ dynamic: true, store, name: 'notify' })
class Notify extends VuexModule implements NotifyState {
  public notify = {
    opened: false,
    items: {},
    message: null
  }

  @Mutation
  private setMessageBag (items) {
    this.notify.opened = true
    this.notify.items = items
    this.notify.message = items
  }

  @Mutation
  private destroyMessageBag () {
    this.notify.opened = false
    this.notify.items = {}
    this.notify.message = null
  }

  @Action
  public set (items) {
    this.setMessageBag(items)
  }

  @Action
  public remove () {
    this.destroyMessageBag()
  }
}

export const NotifyModule = getModule(Notify)
