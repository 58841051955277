import {
  DropdownPlugin,
  TablePlugin,
  ModalPlugin,
  TabsPlugin,
  PaginationPlugin,
  NavPlugin,
  NavbarPlugin,
  CollapsePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  BadgePlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormFilePlugin,
  InputGroupPlugin,
  LinkPlugin,
  ListGroupPlugin,
  FormTimepickerPlugin,
  SidebarPlugin,
  FormDatepickerPlugin,
  FormTagsPlugin,
  BreadcrumbPlugin,
  SpinnerPlugin,
  ProgressPlugin
} from 'bootstrap-vue'

const BootstrapPlugin = {
  install (Vue) {
    Vue.use(DropdownPlugin)
    Vue.use(TablePlugin)
    Vue.use(ModalPlugin)
    Vue.use(TabsPlugin)
    Vue.use(PaginationPlugin)
    Vue.use(NavPlugin)
    Vue.use(NavbarPlugin)
    Vue.use(CollapsePlugin)
    Vue.use(ButtonPlugin)
    Vue.use(ButtonGroupPlugin)
    Vue.use(BadgePlugin)
    Vue.use(FormPlugin)
    Vue.use(FormCheckboxPlugin)
    Vue.use(FormGroupPlugin)
    Vue.use(FormInputPlugin)
    Vue.use(FormRadioPlugin)
    Vue.use(FormSelectPlugin)
    Vue.use(FormTextareaPlugin)
    Vue.use(FormFilePlugin)
    Vue.use(InputGroupPlugin)
    Vue.use(LinkPlugin)
    Vue.use(ListGroupPlugin)
    Vue.use(FormTimepickerPlugin)
    Vue.use(SidebarPlugin)
    Vue.use(FormDatepickerPlugin)
    Vue.use(FormTagsPlugin)
    Vue.use(BreadcrumbPlugin)
    Vue.use(SpinnerPlugin)
    Vue.use(ProgressPlugin)
  }
}

export default BootstrapPlugin
