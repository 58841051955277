import Layout from '@/layout/index.vue'

export default [
  {
    path: '/report',
    redirect: 'reports',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-graph-up-arrow',
      title: 'Reports'
    },
    children: [
      {
        path: '/reports',
        name: 'Report',
        component: () => import(/* webpackChunkName: "reports" */ '../Index.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-graph-up-arrow',
          title: 'Reports'
        }
      },
      {
        path: '/report/job',
        name: 'JobReport',
        component: () => import(/* webpackChunkName: "reports" */ '../components/Job.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-graph-up-arrow',
          title: 'Reports'
        }
      }
    ]
  }
]
