/* eslint-disable dot-notation */
import axios, { AxiosInstance } from 'axios'
import { AuthModule } from '@/store/modules/AuthModule'
import { API_URL } from '@/config/env'

export default class APIService {
    /**
     * Axios instance for API connection
     *
     * @param instance
    **/
    protected readonly connector: AxiosInstance

    /**
     * API response handler
     *
     * @param any
    **/
    protected responseHandler: any = null

    /**
     * Promise status
     *
     * @param bool
    **/
     protected isFulfilled = false

     /**
     * API class constructor
     *
     * @param none
     * @return void
    **/
     public constructor () {
       this.connector = axios.create({
         baseURL: API_URL
       })

       this.setHeaders()
     }

     private setHeaders ():void {
       this.connector.defaults.headers.common['Authorization'] = 'Bearer'
       this.connector.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
     }

     protected destroyHeader ():void {
       this.connector.defaults.headers.common = {}
     }

     /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
     public customRequest (data: any) {
       return this.connector(data)
     }

     public mountAuthorizationToken () {
       this.connector.interceptors.request.use((config) => {
         config.headers.Authorization = `Bearer ${AuthModule.token}`

         return config
       },
       (error) => {
         console.log(error)
       })
     }

     public mount401Response () {
       this.responseHandler = this.connector.interceptors.response.use(
         (response) => {
           return response
         },
         async (error) => {
           if (error.response.status !== 401) {
             return new Promise((resolve, reject) => {
               reject(error)
             })
           }

           if (error.response.status === 401 && error.config.url === '/auth/token') {
             AuthModule.logout()
             return new Promise((resolve, reject) => {
               reject(error)
             })
           }
           AuthModule.validateRefreshToken(error.config)
           // await store.dispatch('auth/refreshToken', )

           // If error was not 401 just reject as is
           // throw error
         }
       )
     }

     unmount401Response () {
       // Eject the interceptor
       this.connector.interceptors.response.eject(this.responseHandler)
     }
}
