
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'

  @Component({
    name: 'NotifyModal'
  })
export default class NotifyModal extends Vue {
    protected items: any = {}
    protected message: any = null

    get isOpened () {
      return NotifyModule.notify.opened
    }

    @Watch('isOpened', {
      deep: true
    })
    notifyItems () {
      if (this.isOpened) {
        if (typeof NotifyModule.notify.items === 'string' || NotifyModule.notify.items instanceof String) {
          this.items = {}
          this.message = NotifyModule.notify.items
        } else {
          this.message = null
          this.items = NotifyModule.notify.items
        }

        this.showModal()
      }
    }

    protected close () {
      this.hideModal()
      this.items = {}
      NotifyModule.remove()
    }

    public showModal () {
      (this.$refs.notifyModal as any).show()
    }

    public hideModal () {
      (this.$refs.notifyModal as any).hide()
    }
}

