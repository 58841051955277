/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/job',
    redirect: '/jobs',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'ac@job',
      icon: 'bi-journals',
      key: 'job',
      title: 'Jobs'
    },
    children: [
      {
        path: '/jobs',
        name: 'JobBoard',
        component: () => import(/* webpackChunkName: "jobs" */ '../Job.vue'),
        meta: {
          authRequired: true,
          permission: 'ac@job',
          icon: 'bi-briefcase',
          title: 'Jobs'
        }
      },
      {
        path: '/job/detail',
        name: 'JobDetail',
        component: () => import(/* webpackChunkName: "jobs" */ '../components/Operation/Layout/Index.vue'),
        meta: {
          authRequired: true,
          permission: 'ac@job',
          title: 'Job Detail'
        }
      }
    ]
  }
]
