/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/vessel',
    redirect: '/vessel/barge',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-journal-plus',
      title: 'Vessel'
    },
    children: [
      {
        path: '/vessel/barge',
        name: 'Barge',
        component: () => import(/* webpackChunkName: "vessel" */ '../Barge.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-people',
          title: 'Barge'
        }
      },
      {
        path: '/vessel/crew',
        name: 'VesselCrew',
        component: () => import(/* webpackChunkName: "accounts" */ '../Crew.vue'),
        meta: {
          authRequired: true,
          permission: 'client-all',
          icon: 'bi bi-people',
          title: 'Vessel Crew'
        }
      },
      {
        path: '/vessel/crew/member',
        name: 'CrewMember',
        component: () => import(/* webpackChunkName: "accounts" */ '../components/CrewMember.vue'),
        meta: {
          authRequired: true,
          permission: 'client-all',
          icon: 'bi bi-people',
          title: 'Vessel Crew Member'
        }
      },
      {
        path: '/vessel/expenses',
        name: 'VesselExpenses',
        component: () => import(/* webpackChunkName: "accounts" */ '../Expense.vue'),
        meta: {
          authRequired: true,
          permission: 'client-all',
          icon: 'bi bi-people',
          title: 'Vessel Expenses'
        }
      }
    ]
  }
]
