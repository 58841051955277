import APIService from './api-service'
import DeviceDetector from 'device-detector-js'
import AuthenticationError from '@/errors/authentication.error'
import AuthorizationError from '@/errors/authorization.error'
import publicIp from 'public-ip'

class AuthService extends APIService {
  public async login (username:string, password:string, ipAddress: string) {
    const deviceData: any = this.getDeviceData()
    const requestData = {
      method: 'post',
      url: 'auth/login',
      data: {
        username: username,
        password: password,
        ip_address: ipAddress,
        browser_name: deviceData.client.name,
        browser_version: deviceData.client.version,
        device: deviceData.device.type,
        os: deviceData.os.name
      }
    }
    try {
      const response = await this.customRequest(requestData)
      this.mount401Response()

      return response.data
    } catch (error: any) {
      throw new AuthenticationError(error.response.data.message)
    }
  }

  /**
     * Refresh the access token.
    **/
  public async refreshToken (refreshToken: string) {
    const requestData = {
      method: 'post',
      url: '/auth/token',
      data: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      }
    }

    try {
      const response = await this.customRequest(requestData)
      return response.data
    } catch (error: any) {
      throw new AuthorizationError(error.response.status, error.response.data.message)
    }
  }

  /**
     * Retring the orginal request
     * after updating access token
    **/
  retryOrginalRequest (orginalRequest: any, token: string) {
    // eslint-disable-next-line dot-notation
    orginalRequest.headers['Authorization'] = 'Bearer ' + token
    orginalRequest.baseURL = undefined

    return new Promise((resolve, reject) => {
      this.customRequest(orginalRequest).then(reponse => {
        resolve(reponse)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
  logout () {
    this.destroyHeader()
    this.unmount401Response()
  }

  /**
     * Get IP address
     *
     * Following api will get public user ip address
     * https://api.ipify.org
     *
    **/
  public async getIpAdress () {
    return await publicIp.v4()
  }

  /**
     * Get user device data
     *
    **/
  public getDeviceData () {
    const deviceDetector = new DeviceDetector()
    return deviceDetector.parse(navigator.userAgent)
  }
}

export default new AuthService()
