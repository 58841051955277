/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/client',
    redirect: '/clients',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-person',
      title: 'Clients'
    },
    children: [
      {
        path: '/clients',
        name: 'Clients',
        component: () => import(/* webpackChunkName: "clients" */ '../Client.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-person',
          title: 'Clients'
        }
      }
    ]
  }
]
