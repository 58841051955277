/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import AuthService from '@/services/auth-service'
import { PermissionModule } from '@/store/modules/PermissionModule'
import JsonMessage from '@/data/message.json'
import AuthorizationError from '@/errors/authorization.error'
import router from '@/router'

export interface AuthState {
  user: {
    id: any,
    fullname: string
  }
  party: {
    id: number,
    type: string
  }
  accessToken: string
  permissions: string[]
}

@Module({ dynamic: true, store, name: 'auth' })
class Auth extends VuexModule {
  public user = {
    id: null,
    fullname: ''
  }

  public party = {
    id: null,
    type: ''
  }

  public accessToken = ''
  public refreshToken = ''
  public permissions: any = []
  public isAuthenticating = false
  protected refreshTokenPromisor = null
  public authMessage: any = {
    isSet: false,
    type: null,
    message: null
  }

  @Mutation
  private setLoginRequest () {
    this.isAuthenticating = true
  }

  @Mutation
  private setLoginSuccess (data:any = {}): void {
    this.accessToken = data.access_token
    this.refreshToken = data.refresh_token
    this.permissions = data.permission.split(',')
    PermissionModule.generateRoutes(this.permissions)
    this.user.id = data.user.id
    this.user.fullname = data.user.fullname
    this.party.id = data.party.id
    this.party.type = data.party.type
  }

  @Mutation
  private setLoginError () {
    this.isAuthenticating = false
  }

  @Mutation
  private setLogout () {
    this.accessToken = ''
    this.refreshToken = ''
    this.permissions = []
    this.user = {
      id: null,
      fullname: ''
    }
    this.party = {
      id: null,
      type: ''
    }
  }

  @Mutation
  protected setAuthMessage (messageKey: any) {
    for (let i = 0, len = JsonMessage.length; i < len; i++) {
      if (JsonMessage[i][messageKey]) {
        this.authMessage.isSet = true
        this.authMessage.type = messageKey.split('_').pop()
        this.authMessage.message = JsonMessage[i][messageKey]
      }
    }
  }

  @Mutation
  protected resetAuthMessage () {
    this.authMessage.isSet = false
    this.authMessage.type = null
    this.authMessage.message = null
  }

  @Action
  public async login (user: { username: string, password: string}) {
    this.setLoginRequest()
    this.setAuthMessage('AUTH_INF')

    try {
      const ipAddress = await AuthService.getIpAdress()
      const data = await AuthService.login(user.username, user.password, ipAddress)
      this.setLoginSuccess(data)
      this.setAuthMessage(data.message)
      PermissionModule.dynamicRoutes.forEach(route => {
        router.addRoute(route)
      })
      // router.history.current.query.redirect ||
      router.push('/authenticated')
      return true
    } catch (error: any) {
      this.setLoginError()
      this.setAuthMessage(error.message)

      return false
    }
  }

  @Action
  public validateRefreshToken (orginalRequest:null) {
    const data = AuthService.refreshToken(this.refreshToken)
    // commit('refreshTokenPromise', data)
    // this.setLoginSuccess(data)

    data.then(
      response => {
        AuthService.retryOrginalRequest(orginalRequest, response)
        // commit('refreshTokenPromise', null)
        this.setLoginSuccess()
      },
      error => {
        // commit('refreshTokenPromise', null)
        if (error instanceof AuthorizationError) {
          console.log(error)
          // this.setLoginError(error.data)
        }
      }
    )
  }

  @Action
  logout () {
    AuthService.logout()
    this.setLogout()
    this.resetAuthMessage()
    router.push('/login')
  }

  get token () {
    return this.accessToken
  }

  get loggedIn () {
    return !!this.accessToken
  }

  get userData () {
    return this.user
  }
}

export const AuthModule = getModule(Auth)
