import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Login from '@/views/auth/Login.vue'
import store from '@/store'
// import APIService from '@/services/api-service'

/* Layout */
import Layout from '@/layout/index.vue'

Vue.use(Router)

const DEFAULT_TITLE = 'Shanika Marine Company'

/**
 * Site routes explained
 *
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    authRequired: bool            control the page roles (you can set multiple roles)
    title: 'title'                Page title
  }
 */

export const constantRoutes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/authenticated',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue'),
        meta: {
          authRequired: true,
          permission: 'basic',
          icon: 'bi bi-speedometer2',
          title: 'Dashboard'
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/auth/Account.vue'),
        meta: {
          authRequired: true,
          permission: 'basic',
          title: 'My Account'
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = []

// Import all of the module routes
function loadAsyncRoutes () {
  const context = require.context('@/views/', true, /index.ts$/i)
  return context.keys()
    .map(context)
    .map((m: any) => m.default)
}

loadAsyncRoutes().forEach((route) => {
  asyncRoutes.push(route[0])
})

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!store.state.auth.accessToken

  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/authenticated')
  }

  next()
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title + ' - Application' || DEFAULT_TITLE
  })
})

export default router
