/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/dispatch',
    redirect: '/delivery',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'ac@job',
      icon: 'bi bi-truck',
      key: 'delivery',
      title: 'delivery'
    },
    children: [
      {
        path: '/delivery',
        name: 'Delivery',
        component: () => import(/* webpackChunkName: "dispatch" */ '../Dispatch.vue'),
        meta: {
          authRequired: true,
          permission: 'ac@job',
          icon: 'bi bi-truck',
          title: 'Delivery'
        }
      }
    ]
  }
]
