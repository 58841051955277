import Layout from '@/layout/index.vue'

export default [
  {
    path: '/scheduled',
    redirect: '/schedule',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-gear',
      title: 'Operations'
    },
    children: [
      {
        path: '/schedule',
        name: 'Schedule',
        component: () => import(/* webpackChunkName: "Schedule" */ '../Schedule.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-calendar2',
          title: 'Schedule'
        }
      }
    ]
  }
]
