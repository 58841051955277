/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/inquiry',
    redirect: '/inquiries',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-journal-plus',
      title: 'Inquiries'
    },
    children: [
      {
        path: '/inquiries',
        name: 'Inquiries',
        component: () => import(/* webpackChunkName: "accounts" */ '../Inquiry.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-people',
          title: 'Inquiries'
        }
      },
      {
        path: '/inquiry/create',
        name: 'InquiryForm',
        component: () => import(/* webpackChunkName: "accounts" */ '../components/InquiryForm.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-people',
          title: 'New Inquiry'
        }
      }
    ]
  }
]
