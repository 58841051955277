
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'

import '@/styles/pages/login.scss'

  @Component({
    name: 'Login'
  })

export default class Login extends Vue {
    private user: any = {
      username: null,
      password: null
    }

    get authNotifierIcon () {
      switch (AuthModule.authMessage.type) {
        case 'INF':
          return 'bi-info-circle'

        case 'ERR':
          return 'bi-x-circle'

        case 'SUC':
          return 'bi-check-circle'

        case 'WAR':
          return 'bi-exclamation-triangle'

        default:
          return 'bi'
      }
    }

    get authNotifierType () {
      switch (AuthModule.authMessage.type) {
        case 'INF':
          return 'alert__info'

        case 'ERR':
          return 'alert__error'

        case 'SUC':
          return 'alert__success'

        case 'WAR':
          return 'alert__warning'

        default:
          return 'alert__default'
      }
    }

    get isAuthNotifier () {
      return AuthModule.authMessage.isSet
    }

    get message () {
      return AuthModule.authMessage.message
    }

    get currentYear () {
      const date: any = new Date()
      return date.getFullYear()
    }

    private login () {
      if (this.user.username !== '' && this.user.password !== '') {
        AuthModule.login(this.user)
      }
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

