/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/employee',
    redirect: '/employees',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@office',
      icon: 'bi bi-person-badge',
      title: 'Employees'
    },
    children: [
      {
        path: '/employees',
        name: 'Employee',
        component: () => import(/* webpackChunkName: "employee" */ '../Employee.vue'),
        meta: {
          authRequired: true,
          permission: 'op@office',
          icon: 'bi bi-person-badge',
          title: 'Employees'
        }
      }
    ]
  }
]
