import APIService from '@/services/api-service'

class StateService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobDetails (jobId: any) {
    return this.connector.get(`job/repository/${jobId}/details`)
  }
}

export default new StateService()
