import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const VSelect = {
  install (Vue) {
    Vue.component('v-select', vSelect)
  }
}

export default VSelect
