/* Layout */
import Layout from '@/layout/index.vue'

export default [
  {
    path: '/voucher',
    redirect: '/voucher/all',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'client-all',
      icon: 'bi bi-journal-plus',
      title: 'Voucher'
    },
    children: [
      {
        path: '/voucher/all',
        name: 'Voucher',
        component: () => import(/* webpackChunkName: "accounts" */ '../Voucher.vue'),
        meta: {
          authRequired: true,
          permission: 'client-all',
          icon: 'bi bi-people',
          title: 'Vouchers'
        }
      }
    ]
  }
]
