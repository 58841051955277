import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import JsonMessage from '@/data/message.json'
import store from '@/store'

export interface ToastState {
  toast: {
    type: string
    message: string
  }
}

@Module({ dynamic: true, store, name: 'toast' })
class Toast extends VuexModule implements ToastState {
  public toast = {
    isSet: false,
    type: '',
    message: ''
  }

  @Mutation
  private setToast (messageObj) {
    this.toast.isSet = true
    this.toast.type = messageObj.type
    this.toast.message = messageObj.message
  }

  @Mutation
  private destroyToast () {
    this.toast.isSet = false
    this.toast.type = ''
    this.toast.message = ''
  }

  @Action
  public message (messageKey: any) {
    for (let i = 0, len = JsonMessage.length; i < len; i++) {
      if (JsonMessage[i][messageKey]) {
        const messageObj = {
          type: messageKey.split('_').pop(),
          message: JsonMessage[i][messageKey]
        }
        this.setToast(messageObj)
      }
    }
  }

  @Action
  public removeToast () {
    this.destroyToast()
  }
}

export const ToastModule = getModule(Toast)
