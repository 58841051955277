
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  AppModule
} from '@/store/modules/AppModule'
// import JobSearchModal from './components/JobSearchModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  // import MessageNotifyModal from './components/MessageNotifyModal.vue'

  @Component({
    name: 'Topbar',
    components: {
      // JobSearchModal
      // MessageNotifyModal
    }
  })
export default class Topbar extends Vue {
    private events = ['click', 'mouseover', 'mousedown', 'scroll', 'keypress', 'load']
    private warningTimer: any = null
    private logoutTimer: any = null
    private setWarning = false

    get title () {
      return BreadcrumbModule.title
    }

    get icon () {
      return BreadcrumbModule.icon
    }

    get user () {
      return AuthModule.userData
    }

    get isCollapse () {
      return !AppModule.sidebar.opened
    }

    get permissions () {
      return AuthModule.permissions
    }

    // mounted () {
    //   this.events.forEach((event) => {
    //     window.addEventListener(event, this.resetTimer)
    //   }, this)

    //   this.setTimers()
    // }

    // destroyed () {
    //   this.events.forEach((event) => {
    //     window.removeEventListener(event, this.resetTimer)
    //   }, this)

    //   this.resetTimer()
    // }

    private openSidebar (): void {
      AppModule.toggleSideBar()
    }

    private setTimers () {
      this.warningTimer = setTimeout(this.warningMessage, 44 * 60 * 1000)
      this.logoutTimer = setTimeout(this.logout, 45 * 60 * 1000)

      this.setWarning = false
    }

    private warningMessage () {
      this.setWarning = true
    }

    private resetTimer () {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)

      this.setTimers()
    }

    private logout () {
      AuthModule.logout()
    }

    private openModal () {
      (this.$refs.messageNotifyModal as any).showModal()
    }

    private openJobSearchModal () {
      (this.$refs.jobSearchModal as any).show()
    }
}

