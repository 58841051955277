import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface BreadcrumbState {
  title: any
  icon: any
}

@Module({ dynamic: true, store, name: 'breadcrumb' })
class Breadcrumb extends VuexModule implements BreadcrumbState {
  public title: any = null
  public icon: any = null

  @Mutation
  protected setBreadcrumbAttributes (data: any) {
    this.title = data.meta.title
    this.icon = data.meta.icon
  }

  @Mutation
  protected destroyBreadcrumbAttributes () {
    this.title = null
    this.icon = null
  }

  @Action
  public setBreadcrumb (data: any) {
    this.setBreadcrumbAttributes(data)
  }
}

export const BreadcrumbModule = getModule(Breadcrumb)
