import VueHtmlToPaper from 'vue-html-to-paper'

const htmlToPaper = {
  install (Vue) {
    Vue.use(VueHtmlToPaper, {
      name: '_blank',
      specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
      ],
      styles: [
        'https://api.smc-spl-application.com/print.css'
      ],
      timeout: 1000, // default timeout before the print window appears
      autoClose: true, // if false, the window will not close after printing
      windowTitle: window.document.title // override the window title
    })
  }
}

export default htmlToPaper
