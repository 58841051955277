class AuthorizationError extends Error {
  constructor (errorCode: number, message:string) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    // this.errorCode = errorCode
  }
}

export default AuthorizationError
