import DatePick from 'vue-date-pick'
// import 'vue-date-pick/dist/vueDatePick.css'

const DatePickPlugin = {
  install (Vue) {
    Vue.component('date-pick', DatePick)
  }
}

export default DatePickPlugin
