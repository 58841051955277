
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  SidebarMenu
} from 'vue-sidebar-menu'
import {
  AppModule
} from '@/store/modules/AppModule'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  JobModule
} from '@/views/job/store/JobModule'
import OfficeRoutes from '../Routes/office-routes.json'
import BargeRoutes from '../Routes/barge-routes.json'
import SlpaRoutes from '../Routes/slpa-routes.json'
import DeliveryRoutes from '../Routes/delivery-routes.json'
import YardRoutes from '../Routes/yard-routes.json'
import TopRoutes from '../Routes/top-routes.json'

  @Component({
    name: 'Sidebar',
    components: {
      SidebarMenu
    }
  })

export default class Sidebar extends Vue {
    public width = '235px'
    public widthCollapsed = '55px'
    public disableHover = true
    private items: any = []

    get sidebarItems () {
      return this.items
    }

    get isCollapse () {
      return AppModule.sidebar.collapsed
    }

    get isToggled () {
      return !AppModule.sidebar.opened
    }

    private onToggleCollapse (): void {
      AppModule.collapsedSidebar()
    }

    created () {
      this.routeStructure()
    }

    private routeStructure () {
      if (AuthModule.permissions.includes('op@bill')) {
        this.items = TopRoutes
        return true
      }

      if (AuthModule.permissions.includes('op@office')) {
        this.items = OfficeRoutes
        return true
      }

      if (AuthModule.permissions.includes('op@barge')) {
        this.items = BargeRoutes
        return true
      }

      if (AuthModule.permissions.includes('op@slpa')) {
        this.items = SlpaRoutes
        return true
      }

      if (AuthModule.permissions.includes('op@delivery')) {
        this.items = DeliveryRoutes
        return true
      }

      if (AuthModule.permissions.includes('op@yard')) {
        this.items = YardRoutes
        return true
      }
    }

    protected onItemClick (event: any, item: any) {
      if (item.key === 'job') {
        JobModule.destroy()
      }
    }
}

