import Vue from 'vue'
import Vuex from 'vuex'
import { AuthState } from './modules/AuthModule'
import { PermissionState } from './modules/PermissionModule'
import { AppState } from './modules/AppModule'
import { JobState } from '../views/job/store/JobModule'
import { ClientState } from '../views/client/store/ClientModule'
import { CompanyState } from '../views/company/store/CompanyModule'
import { OperationState } from '../views/operation/store/OperationModule'
import { MasterState } from './modules/MasterModule'
import { BreadcrumbState } from './modules/BreadcrumbModule'
import { ToastState } from './modules/ToastModule'
import { ErrorState } from './modules/ErrorModule'
import { NotifyState } from './modules/NotifyModule'

Vue.use(Vuex)

export interface RootState {
  auth: AuthState
  permission: PermissionState
  app: AppState
  job: JobState
  breadcrumb: BreadcrumbState
  toast: ToastState
  error: ErrorState
  notify: NotifyState
  client: ClientState
  company: CompanyState
  operation: OperationState
  master: MasterState
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({})
