import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/app.scss'
// import '@/styles/print.scss'

import Bootstrap from '@/plugins/bootstrap'
import BootstrapTypeahead from '@/plugins/typeahead'
import VSelect from '@/plugins/vue-select'
import Vuelidate from 'vuelidate'
import htmlToPaper from '@/plugins/html-paper'
import DatePickPlugin from '@/plugins/datetime-pick'

import SettingService from '@/services/settings-service'

Vue.use(Bootstrap)
Vue.use(BootstrapTypeahead)
Vue.use(Vuelidate)
Vue.use(VSelect)
Vue.use(htmlToPaper)
Vue.use(DatePickPlugin)

Vue.config.productionTip = false

async function validateAppVersion () {
  const localVersion: any = SettingService.getLocalAppVersion()
  const response = await SettingService.getServerAppVersion()

  if (localVersion === null) {
    SettingService.setLocalAppVersion(response.data)
    return false
  }

  if (localVersion !== response.data) {
    SettingService.setLocalAppVersion(response.data)
    window.location.reload()
  }
}

validateAppVersion()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
