import Layout from '@/layout/index.vue'

export default [
  {
    path: '/invoicing',
    redirect: '/invoice',
    component: Layout,
    meta: {
      authRequired: true,
      permission: 'op@bill',
      icon: 'bi-file-earmark-text',
      title: 'Invoices'
    },
    children: [
      {
        path: '/invoice',
        name: 'Invoicing',
        component: () => import(/* webpackChunkName: "invoice" */ '../Index.vue'),
        meta: {
          authRequired: true,
          permission: 'op@bill',
          icon: 'bi-file-earmark-text',
          title: 'Invoicing'
        }
      }
    ]
  }
]
